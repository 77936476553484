import { Session } from '../js/scripts/session';
import { jwtDecode } from "jwt-decode";

//##############################################################################################################
// ARQUIVO DE SERVIÇO DE TRATAIVAS DO JWT-TOKEN
//##############################################################################################################

// Decodifica o JwtToken
export function decodeJwtToken(){
    if (Session.JwtToken){
        const decodedToken = jwtDecode(Session.JwtToken);

        return decodedToken;
    }
    
    return null;
}