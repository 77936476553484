const languages = {
      pt: {
            menu_consulta_endereco: "Consulta Endereço",
            menu_consulta_endereco_cep_numero: "Cep / Número",
            menu_consulta_endereco_logradouro: "Logradouro",

            menu_consulta_facilidades: "Consultar / Ativar Reserva",
            menu_cancelamento_reserva: "Cancelar Reserva",

            menu_cancelamento_circuito_ativo: "Cancelar Circuito Ativo",

            menu_suspensao_recurso: "Circuitos Ativos",
            menu_desativacao_recurso: "Cancelamento",

            menu_troca_velocidade: "Alteração de velocidade",
            menu_troca_servico: "Alteração de serviço",
            menu_troca_equipamento: "Troca de equipamento",

            menu_teste_diagnostico: "Diagnóstico",
            menu_reaplicacao_config: "Reaplicação de configuração",
            menu_reboot_ont: "Reset de equipamento",
            menu_manobra_cto: "Manobra Cto",
            menu_estatistica_trafego: "Estatísticas de Tráfego",
            menu_alarmes_ont: "Alarmes da Ont",

            menu_gestao_vno: "VNO(s)",
            menu_gestao_vno_cadastro: "Criar um ISP",
            menu_gestao_servico: "Gerenciar Serviço(s)",

            menu_consultar_usuario: "Consultar Usuário",

            carregando: "Carregando...",
            btn_fechar: "Fechar",

            consultar: "Consultar",
            serviceid: "Service Id",

            //Login
            bem_vindo: "Seja bem-vindo!",
            email: "Email",
            senha: "Senha",
            login: "Login",
            tit_credenciais_incorretas: "Credenciais incorretas!",
            desc_credenciais_incorretas: "Favor inserir email e senha corretamente.",
            tit_erro_inesperado: "Ocorreu um erro inesperado.",
            desc_erro_inesperado: "Favor entre em contato com o administrador do sistema",

            //Autenticacao
            autenticacao_dois_fatores: "Autenticação de dois fatores",
            desc_explicativo_dois_fatores: "Insira o código de 6 dígitos que enviamos para seu email corporativo, para prosseguir com a autenticação de dois fatores.",
            codeInput: "Código de Verificação",
            verificar: "Verificar",
            reenviar_codigo: "Reenviar Código",
            voltar: "Voltar",
            preencha_este_campo: "Preencha este campo.",
            tit_token_expirado: "Token expirado.",
            desc_token_expirado: "Solicite um novo código clicando no botão abaixo.",
            tit_token_invalido: "Token inválido.",
            desc_token_invalido: "Solicite um novo código clicando no botão abaixo.",
            tit_verificar_caixa_portal: "Verificar sua caixa postal.",
            desc_verificar_caixa_portal: "Enviamos um código de verificação temporário para seu email cadastrado. Insira o código no campo abaixo.",

            //Verificar Usuário
            verificar_usuario: " Verificação do usuário",
            desc_explicativo_verificar_usuario: "Antes de começarmos, precisamos identificar o seu cadastro. Utilize o mesmo email cadastrado no momento de criação da conta.",

            // Sucesso
            tit_senha_alterada_sucesso: "Sucesso!",
            desc_senha_alterada_sucesso: "Senha alterada com sucesso, agora voce já pode utilizar o sistema. Foi enviado um novo token para autenticação. Clique no botão abaixo para Entrar.",
            entrar: "Entrar",

            // Primeiro Acesso
            tit_primeiro_acesso: "Primeiro Acesso",
            desc_primeiro_acesso: "Este é o seu primeiro acesso para geração de senha para seu sistema. Para continuar voce deve escolher uma nova senha. ",
            txtSenha: "Senha",
            txt_confirmacao_senha: "Confirmar Senha",
            btn_alterar_senha: "Alterar Senha",
            btn_alterar: "Alterar",



            // Modal Information
            atencao: "Atenção",
            sucesso: "Sucesso",

            // Modal Viabilidade
            consulta_viabilidade: "Consulta de viabilidade",
            btn_ir_para_reserva: "Ir para Reserva",
            ponto_com_viabilidade: "Ponto com Viabilidade",
            ponto_sem_viabilidade: "Ponto sem Viabilidade",
            velocidade_maxima: "Velocidade Máx.",

            // Modal Reserva
            reserva: "Reserva",
            velocidade_mb: "Velocidade (Mb)",
            segmento: "Segmento",
            informacoes_adicionais: "Informações Adicionais",
            efetuar_reserva: "Efetuar Reserva",
            selecione: "Selecione",

            // Tables
            tab_service_id: "Service ID",
            tab_data_reserva: "Data da Reserva",
            tab_data_ativacao: "Data da Ativação",
            tab_data_expiracao: "Data de Expiração",
            tab_velocidade: "Velocidade",
            tab_servico: "Serviço",
            tab_situacao: "Situação",
            tab_vno: "VNO",
            tab_acao: "Ação",
            tab_diagnostico: "Diagnóstico",
            tab_suspensao: "Suspensão",
            tab_reativacao: "Reativação",
            tab_detalhe: "Detalhe",
            tab_title_suspender_servico: "Suspender Serviço",
            tab_title_restabelecer_servico: "Restabelecer Serviço",
            tab_title_diagnostico: "Diagnóstico",
            tab_title_consultar: "Consultar",
            tab_title_desativar: "Desativar",
            tab_title_alterar: "Alterar",

            // Modal Service Change
            troca_de_servico: "Troca de serviço",
            servico_atual: "Serviço Atual",
            servico_solicitado: "Serviço Solicitado",

            // Modal Speed Change
            troca_de_velocidade: "Troca de velocidade",
            velocidade_atual: "Velocidade Atual",
            velocidade_solicitada: "Velocidade Solicitada",

            // Modal Suspensão de Recurso
            suspensao_de_recurso: "Suspensão de Recurso",
            btn_suspender_recurso: "Suspender recurso",

            // CANCELAMENTO
            tit_erro_cancelamento: "Cancelamento NÃO realizado!",

            // BREADCRUMB
            breadcrumbs_titulo_reserva: "RESERVA",
            breadcrumbs_subtitulo_consultar: "CONSULTAR",
            breadcrumbs_subtitulo_cancelamento: "CANCELAMENTO",
            breadcrumbs_titulo_suporte_tecnico: "SUPORTE TÉCNICO",
            breadcrumbs_subtitulo_diagnostico: "DIAGNÓSTICO",
            breadcrumbs_titulo_pos_venda: "PÓS VENDA",
            breadcrumbs_titulo_troca_equipamento: "TROCA DE EQUIPAMENTO",
            breadcrumbs_titulo_estatistica_trafego: "ESTATÍSTICAS DE TRÁFEGO",
            breadcrumbs_titulo_reaplicacao_conf: "REAPLICAÇÃO DE CONFIGURAÇÃO",
            breadcrumbs_titulo_reset_equip: "RESET DE EQUIPAMENTO",
            breadcrumbs_titulo_alarms: "ALARMES DA ONT",

            // MODAL TESTE DE SERVICO
            internet: "INTERNET",
            suspenso: "SUSPENSO",
            ativado: "ATIVADO",

            // MODAL EQUIP CHANGE
            desc_equipamento_alterado_sucesso: "Alteração de equipamento realizada com sucesso!",
            btn_alterar_equipamento: "Alterar",
            desc_erro_trocar_equipamento: "UM ERRO OCORREU",
            desc_warning_ope_naopermitida_vno: "Operação não permitida para esse VNO.",
            desc_msg_troca_nãorealizada: "Troca de equipamento NÃO realizada!",

            // MODAL STATISTICS 
            desc_msg_sucesso_statistics: "Operação realizada com sucesso!",
            desc_erro_statistic: "UM ERRO OCORREU",
            // MANOBRA


            //REAPPLY
            desc_reapply_sucesso: "Reaplicação de configuração realizada com sucesso!",
            tit_reapply_config: "Reaplicação de configuração",
            desc_reapply_config_ont: "Esta ação reaplicará todas as configurações da ONT.",
            desc_erro_reapply: "UM ERRO OCORREU",

            //RESET
            desc_reset_equip: "Reset de equipamento realizado com sucesso!",
            tit_reset_equip: "Reset no Equipamento",
            desc_reset_equip_ont: "Esta ação realizará um reboot na ONT.",
            desc_erro_reset_equip: "UM ERRO OCORREU",

            //ALARMS
            desc_alarms_sucesso: "Operação realizada com sucesso!",
            tit_alarms_ont: "Alarmes da ONT",
            desc_alarms_ont: "Descrição da funcionalidade",
            desc_erro_alarms_ont: "UM ERRO OCORREU",

            endereco_nao_encontrado: "Endereço não encontrado"


      },
      en: {
            menu_consulta_endereco: "Search Address",
            menu_consulta_endereco_cep_numero: "ZIP Code / Number",
            menu_consulta_endereco_logradouro: "Street Address",

            menu_consulta_facilidades: "Consult / Activate Reservation",
            menu_cancelamento_reserva: "Cancel Reservation",

            menu_cancelamento_circuito_ativo: "Cancelar Circuito Ativo",

            menu_suspensao_recurso: "Active Circuits",
            menu_desativacao_recurso: "Cancellation",

            menu_troca_velocidade: "Speed Change",
            menu_troca_servico: "Service Change",
            menu_troca_equipamento: "Equipment Change",

            menu_teste_diagnostico: "Diagnostic",
            menu_reaplicacao_config: "Configuration Reapplication",
            menu_reboot_ont: "Equipment Reset",
            menu_manobra_cto: "CTO Maneuver",
            menu_estatistica_trafego: "Traffic Statistics",
            menu_alarmes_ont: "ONT Alarms",

            menu_gestao_vno: "VNO(s)",
            menu_gestao_vno_cadastro: "Create an ISP",
            menu_gestao_servico: "Manage Service(s)",



            carregando: "Loading...",
            btn_fechar: "Close",

            consultar: "Consult",
            serviceid: "Service Id",

            //Login
            bem_vindo: "Welcome!",
            email: "Email",
            senha: "Password",
            login: "Login",
            tit_credenciais_incorretas: "Incorrect credentials!",
            desc_credenciais_incorretas: "Please enter email and password correctly.",
            tit_erro_inesperado: "An unexpected error occurred.",
            desc_erro_inesperado: "Please contact the system administrator",

            //Autenticacao
            autenticacao_dois_fatores: "Two-Factor Authentication",
            desc_explicativo_dois_fatores: "Enter the 6-digit code we sent to your corporate email to proceed with two-factor authentication.",
            codeInput: "Verification Code",
            verificar: "Verify",
            reenviar_codigo: "Resend Code",
            voltar: "Back",
            preencha_este_campo: "Fill in this field.",
            tit_token_expirado: "Token expired.",
            desc_token_expirado: "Request a new code by clicking the button below.",
            tit_token_invalido: "Invalid token.",
            desc_token_invalido: "Request a new code by clicking the button below.",
            tit_verificar_caixa_portal: "Check your mailbox.",
            desc_verificar_caixa_portal: "We sent a temporary verification code to your registered email. Enter the code in the field below.",

            // Sucesso
            tit_senha_alterada_sucesso: "Success!",
            desc_senha_alterada_sucesso: "Password changed successfully, now you can use the system. Click the button below to Log In.",
            entrar: "Log In",

            // Primeiro Acesso
            tit_primeiro_acesso: "First Access",
            desc_primeiro_acesso: "This is your first access to generate a password for your system. To continue, you must choose a new password.",
            txtSenha: "Password",
            txt_confirmacao_senha: "Confirm Password",
            btn_alterar_senha: "Change Password",
            btn_alterar: "Change",

            // Modal Information
            atencao: "Attention",
            sucesso: "Success",

            // Modal Viabilidade
            consulta_viabilidade: "Feasibility Inquiry",
            btn_ir_para_reserva: "Go to Reservation",
            ponto_com_viabilidade: "Point with Feasibility",
            ponto_sem_viabilidade: "Point without Feasibility",
            velocidade_maxima: "Maximum Speed",

            // Modal Reserva
            reserva: "Reservation",
            velocidade_mb: "Speed (Mb)",
            segmento: "Segment",
            informacoes_adicionais: "Additional Information",
            efetuar_reserva: "Make Reservation",
            selecione: "Select",

            // Tables
            tab_service_id: "Service ID",
            tab_data_reserva: "Reservation Date",
            tab_data_ativacao: "Activation Date",
            tab_data_expiracao: "Expiration Date",
            tab_velocidade: "Speed",
            tab_servico: "Service",
            tab_situacao: "Status",
            tab_vno: "VNO",
            tab_acao: "Action",
            tab_diagnostico: "Diagnostic",
            tab_suspensao: "Suspension",
            tab_reativacao: "Reactivation",
            tab_detalhe: "Detail",
            tab_title_suspender_servico: "Suspend Service",
            tab_title_restabelecer_servico: "Restore Service",
            tab_title_diagnostico: "Diagnostic",
            tab_title_consultar: "Consult",
            tab_title_desativar: "Deactivate",
            tab_title_alterar: "Change",

            // Modal Service Change
            troca_de_servico: "Service Change",
            servico_atual: "Current Service",
            servico_solicitado: "Requested Service",

            // Modal Speed Change
            troca_de_velocidade: "Speed Change",
            velocidade_atual: "Current Speed",
            velocidade_solicitada: "Requested Speed",

            // Modal Suspensão de Recurso
            suspensao_de_recurso: "Resource Suspension",
            btn_suspender_recurso: "Suspend Resource",

            // CANCELAMENTO
            tit_erro_cancelamento: "Cancellation NOT performed!",

            // BREADCRUMB
            breadcrumbs_titulo_reserva: "RESERVATION",
            breadcrumbs_subtitulo_consultar: "CONSULT",
            breadcrumbs_subtitulo_cancelamento: "CANCELLATION",
            breadcrumbs_titulo_suporte_tecnico: "TECHNICAL SUPPORT",
            breadcrumbs_subtitulo_diagnostico: "DIAGNOSIS",
            breadcrumbs_titulo_pos_venda: "AFTER SALES",
            breadcrumbs_titulo_troca_equipamento: "EQUIPMENT CHANGE",
            breadcrumbs_titulo_estatistica_trafego: "TRAFFIC STATISTICS",
            breadcrumbs_titulo_reaplicacao_conf: "CONFIGURATION REAPPLICATION",
            breadcrumbs_titulo_reset_equip: "EQUIPMENT RESET",
            breadcrumbs_titulo_alarms: "ONT ALARMS",

            // MODAL TESTE DE SERVICO
            internet: "INTERNET",
            suspenso: "SUSPENDED",
            ativado: "ACTIVATED",

            // MODAL EQUIP CHANGE
            desc_equipamento_alterado_sucesso: "Equipment change successfully performed!",
            btn_alterar_equipamento: "Change",
            desc_erro_trocar_equipamento: "AN ERROR OCCURRED",
            desc_warning_ope_naopermitida_vno: "Operation not allowed for this VNO.",
            desc_msg_troca_nãorealizada: "Equipment change NOT performed!",

            // MODAL STATISTICS
            desc_msg_sucesso_statistics: "Operation performed successfully!",
            desc_erro_statistic: "AN ERROR OCCURRED",
            // MANOBRA

            //REAPPLY
            desc_reapply_sucesso: "Configuration reapplication successfully performed!",
            tit_reapply_config: "Configuration Reapplication",
            desc_reapply_config_ont: "This action will reapply all ONT configurations.",
            desc_erro_reapply: "AN ERROR OCCURRED",

            //RESET
            desc_reset_equip: "Equipment reset successfully performed!",
            tit_reset_equip: "Equipment Reset",
            desc_reset_equip_ont: "This action will reboot the ONT.",
            desc_erro_reset_equip: "AN ERROR OCCURRED",

            //ALARMS
            desc_alarms_sucesso: "Operation performed successfully!",
            tit_alarms_ont: "ONT Alarms",
            desc_alarms_ont: "Description of the functionality",
            desc_erro_alarms_ont: "AN ERROR OCCURRED",

            endereco_nao_encontrado: "Address not found"
      },
      es: {
            menu_consulta_endereco: "Consulta de Dirección",
            menu_consulta_endereco_cep_numero: "Código Postal / Número",
            menu_consulta_endereco_logradouro: "Calle",

            menu_consulta_facilidades: "Consultar / Activar Reserva",
            menu_cancelamento_reserva: "Cancelar Reserva",

            menu_cancelamento_circuito_ativo: "Cancelar Circuito Ativo",

            menu_suspensao_recurso: "Circuitos Activos",
            menu_desativacao_recurso: "Cancelación",

            menu_troca_velocidade: "Cambio de Velocidad",
            menu_troca_servico: "Cambio de Servicio",
            menu_troca_equipamento: "Cambio de Equipo",

            menu_teste_diagnostico: "Diagnóstico",
            menu_reaplicacao_config: "Reaplicación de Configuración",
            menu_reboot_ont: "Reinicio de Equipo",
            menu_manobra_cto: "Maniobra de CTO",
            menu_estatistica_trafego: "Estadísticas de Tráfico",
            menu_alarmes_ont: "Alarmas de la ONT",

            menu_gestao_vno: "VNO(s)",
            menu_gestao_vno_cadastro: "Crear ISP",
            menu_gestao_servico: "Gestionar Servicio(s)",

            carregando: "Cargando...",
            btn_fechar: "Cerrar",

            consultar: "Consultar",
            serviceid: "ID de Servicio",

            //Login
            bem_vindo: "¡Bienvenido!",
            email: "Correo Electrónico",
            senha: "Contraseña",
            login: "Iniciar Sesión",
            tit_credenciais_incorretas: "¡Credenciales Incorrectas!",
            desc_credenciais_incorretas: "Por favor, ingrese el correo electrónico y la contraseña correctamente.",
            tit_erro_inesperado: "Se ha producido un error inesperado.",
            desc_erro_inesperado: "Por favor, póngase en contacto con el administrador del sistema",

            //Autenticacion
            autenticacao_dois_fatores: "Autenticación de Dos Factores",
            desc_explicativo_dois_fatores: "Ingrese el código de 6 dígitos que le enviamos a su correo electrónico corporativo, para continuar con la autenticación de dos factores.",
            codeInput: "Código de Verificación",
            verificar: "Verificar",
            reenviar_codigo: "Reenviar Código",
            voltar: "Volver",
            preencha_este_campo: "Complete este campo.",
            tit_token_expirado: "Token Expirado.",
            desc_token_expirado: "Solicite un nuevo código haciendo clic en el botón de abajo.",
            tit_token_invalido: "Token Inválido.",
            desc_token_invalido: "Solicite un nuevo código haciendo clic en el botón de abajo.",
            tit_verificar_caixa_portal: "Verificar su casilla de correo.",
            desc_verificar_caixa_portal: "Hemos enviado un código de verificación temporal a su correo electrónico registrado. Ingrese el código en el campo a continuación.",

            // Éxito
            tit_senha_alterada_sucesso: "¡Éxito!",
            desc_senha_alterada_sucesso: "Contraseña cambiada con éxito, ahora puede usar el sistema. Haga clic en el botón de abajo para Iniciar Sesión.",
            entrar: "Iniciar Sesión",

            // Primer Acceso
            tit_primeiro_acesso: "Primer Acceso",
            desc_primeiro_acesso: "Este es su primer acceso para generar una contraseña para su sistema. Para continuar, debe elegir una nueva contraseña.",
            txtSenha: "Contraseña",
            txt_confirmacao_senha: "Confirmar Contraseña",
            btn_alterar_senha: "Cambiar Contraseña",
            btn_alterar: "Cambiar",

            // Modal de Información
            atencao: "Atención",
            sucesso: "Éxito",

            // Modal de Viabilidad
            consulta_viabilidade: "Consulta de Viabilidad",
            btn_ir_para_reserva: "Ir a Reserva",
            ponto_com_viabilidade: "Punto con Viabilidad",
            ponto_sem_viabilidade: "Punto sin Viabilidad",
            velocidade_maxima: "Velocidad Máxima",

            // Modal de Reserva
            reserva: "Reserva",
            velocidade_mb: "Velocidad (Mb)",
            segmento: "Segmento",
            informacoes_adicionais: "Información Adicional",
            efetuar_reserva: "Realizar Reserva",
            selecione: "Seleccionar",

            // Tablas
            tab_service_id: "ID de Servicio",
            tab_data_reserva: "Fecha de Reserva",
            tab_data_ativacao: "Fecha de Activación",
            tab_data_expiracao: "Fecha de Expiración",
            tab_velocidade: "Velocidad",
            tab_servico: "Servicio",
            tab_situacao: "Situación",
            tab_vno: "VNO",
            tab_acao: "Acción",
            tab_diagnostico: "Diagnóstico",
            tab_suspensao: "Suspensión",
            tab_reativacao: "Reactivación",
            tab_detalhe: "Detalle",
            tab_title_suspender_servico: "Suspender Servicio",
            tab_title_restabelecer_servico: "Restablecer Servicio",
            tab_title_diagnostico: "Diagnóstico",
            tab_title_consultar: "Consultar",
            tab_title_desativar: "Desactivar",
            tab_title_alterar: "Cambiar",

            // Modal de Cambio de Servicio
            troca_de_servico: "Cambio de Servicio",
            servico_atual: "Servicio Actual",
            servico_solicitado: "Servicio Solicitado",

            // Modal de Cambio de Velocidad
            troca_de_velocidade: "Cambio de Velocidad",
            velocidade_atual: "Velocidad Actual",
            velocidade_solicitada: "Velocidad Solicitada",

            // Modal de Suspensión de Recurso
            suspensao_de_recurso: "Suspensión de Recurso",
            btn_suspender_recurso: "Suspender Recurso",

            // CANCELAMENTO
            tit_erro_cancelamento: "¡Cancelación NO realizada!",

            // MIGAJAS DE PAN
            breadcrumbs_titulo_reserva: "RESERVA",
            breadcrumbs_subtitulo_consultar: "CONSULTAR",
            breadcrumbs_subtitulo_cancelamento: "CANCELACIÓN",
            breadcrumbs_titulo_suporte_tecnico: "SOPORTE TÉCNICO",
            breadcrumbs_subtitulo_diagnostico: "DIAGNÓSTICO",
            breadcrumbs_titulo_pos_venda: "POSVENTA",
            breadcrumbs_titulo_troca_equipamento: "CAMBIO DE EQUIPO",
            breadcrumbs_titulo_estatistica_trafego: "ESTADÍSTICAS DE TRÁFICO",
            breadcrumbs_titulo_reaplicacao_conf: "REAPLICACIÓN DE CONFIGURACIÓN",
            breadcrumbs_titulo_reset_equip: "REINICIO DE EQUIPO",
            breadcrumbs_titulo_alarms: "ALARMAS DE LA ONT",

            // Modal de Prueba de Servicio
            internet: "INTERNET",
            suspenso: "SUSPENDIDO",
            ativado: "ACTIVADO",

            // Modal de Cambio de Equipo
            desc_equipamento_alterado_sucesso: "¡Cambio de equipo realizado con éxito!",
            btn_alterar_equipamento: "Cambiar",
            desc_erro_trocar_equipamento: "HA OCURRIDO UN ERROR",
            desc_warning_ope_naopermitida_vno: "Operación no permitida para este VNO.",
            desc_msg_troca_nãorealizada: "¡Cambio de equipo NO realizado!",

            // Modal de Estadísticas
            desc_msg_sucesso_statistics: "¡Operación realizada con éxito!",
            desc_erro_statistic: "HA OCURRIDO UN ERROR",
            // MANIOBRA

            //REAPPLY
            desc_reapply_sucesso: "¡Reaplicación de configuración realizada con éxito!",
            tit_reapply_config: "Reaplicación de configuración",
            desc_reapply_config_ont: "Esta acción reaplicará todas las configuraciones de la ONT.",
            desc_erro_reapply: "HA OCURRIDO UN ERROR",

            //RESET
            desc_reset_equip: "¡Reinicio de equipo realizado con éxito!",
            tit_reset_equip: "Reinicio de Equipo",
            desc_reset_equip_ont: "Esta acción reiniciará la ONT.",
            desc_erro_reset_equip: "HA OCURRIDO UN ERROR",

            //ALARMS
            desc_alarms_sucesso: "¡Operación realizada con éxito!",
            tit_alarms_ont: "Alarmas de la ONT",
            desc_alarms_ont: "Descripción de la funcionalidad",
            desc_erro_alarms_ont: "HA OCURRIDO UN ERROR",

            endereco_nao_encontrado: "Dirección no encontrada"

      }
}

export { languages }